export const useSupportLinks = () => {
  const appStore = useAppStore()
  const authStore = useAuthStore()

  // Faqs
  const faqsLink = computed(() => appStore.ecommerceConfig?.urlFaqs)

  // Support - WhatsApp or Salesforce chat
  const supportLink = computed(() => {
    const url = appStore.ecommerceConfig?.urlWhatsApp
    if (!url) return undefined
    // add mcId to support link
    const mcId = authStore.userMcId
    if (mcId) {
      try {
        const urlBuild = new URL(url)
        urlBuild.searchParams.append('mcId', mcId)
        return urlBuild.toString()
      } catch (error) {
        return `${url}${url.includes('?') ? '&' : '?'}mcId=${mcId}`
      }
    }
    return url
  })

  const supportIcon = computed(
    () => appStore.ecommerceConfig?.logoChatSupport || '/images/icons/help/whatsapp.svg'
  )
  const supportLinkName = computed(() => appStore.ecommerceConfig?.nameChatSupport)

  // Sac
  const sacLink = computed(() => appStore.ecommerceConfig?.sacPhoneNumber)

  // Chat sales force active
  const activeChatSalesforce = computed(() => appStore.ecommerceConfig?.activeSalesforceSupport)

  return {
    faqsLink,
    supportLink,
    supportIcon,
    supportLinkName,
    sacLink,
    activeChatSalesforce,
  }
}
